import React, { useState } from "react"
import "../assets/styles/_index.scss"
import {
  ArrowLeft,
  ArrowRight,
  CurrencyRupee,
  GeoAlt,
} from "react-bootstrap-icons"
import { Button, Card, Col, Container, Image, Row } from "react-bootstrap"
import { Link } from "gatsby"

import prevArrow from '/utility/assets/icons/prevArrow.png'
import nextArrow from '/utility/assets/icons/nextArrow.png'

import Brochureimage from "/utility/assets/common/details/brochure-image.png"
import Floorplanimage from "/utility/assets/common/details/floor-plan-image.png"
import Projectpriceimage from "/utility/assets/common/details/project-price-image.png"
import Projectvideoimage from "/utility/assets/common/details/project-video-image.png"
import PropTVimage from "/utility/assets/common/details/prop-tv-image.png"

import BirlaAlokyaLogo from "/utility/assets/birla-estates/our-properties/Birla-Alokya.png"
import Slider from "react-slick"
import VideoPlayerTv from "../../../videoplayerTv/VideoPlayerTv"
import ImageGalleryModal from "../../../ImageGalleryModal/ImageGalleryModal"
import DownloadFileModal from '../../../downloadfilemodal/DownloadFileModal';
import FileModal from "../../../filemodal/FileModal"

const videoUrl = "https://www.propverse.ai/utility/assets/birla-estates/birla-alokya/BirlaAlokya.mp4"

const thumbnailUrl = require("/utility/assets/common/prop-tv-image.png")






const CustomPrevArrow = props => {
  const { className, style, onClick } = props
  return (
    <div
      className={className}
      style={{
        ...style,
        width: "5px",
        height: "5px",
        backgroundImage: `url(${prevArrow})`,
      }}
      onClick={onClick}
    />
  )
}

const CustomNextArrow = props => {
  const { className, style, onClick } = props
  return (
    <div
      className={className}
      style={{
        ...style,
        width: "5px",
        height: "5px",
        backgroundImage: `url(${nextArrow})`,
      }}
      onClick={onClick}
    />
  )
}

const BirlaAlokyaSidebar = ({ isOpen, toggleRightSidebar, id }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDownlaodModalOpen, setIsDownloadModalOpen] = useState(false);


  const openDownloadModal = () => {
    setIsDownloadModalOpen(true)

    
  };

  const closedownloadModal = () => {
    setIsDownloadModalOpen(false)
  };

  const openModal = () => {
    setIsModalOpen(true);
    
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };



  const handleFloorViewButtonClick = () => {
    openModal()
  }

  const handleBrochureViewButtonClick = () => {
  
  window.open("/utility/assets/birla-estates/birla-alokya/BirlaBrochure.pdf")
  }

  const handleVideoViewButtonClick = () => {
    window.open("/utility/assets/birla-estates/birla-alokya/BirlaAlokya.mp4")
 
  }

  const handlePriceViewButtonClick = () => {
    window.open("/utility/assets/birla-estates/birla-alokya/PriceList.jpeg")
   
   }

   
   const handleBrochureDownloadButtonClick = () => {
  
    const pdfUrl = '/utility/assets/birla-estates/birla-alokya/BirlaBrochure.pdf';

    const anchor = document.createElement('a');
    anchor.href = pdfUrl;
    anchor.download = 'Broucher.pdf';
    anchor.click();

    }

    const handleVideoDownloadButtonClick = () => {
      const videoUrl = '/utility/assets/birla-estates/birla-alokya/BirlaAlokya.mp4';
      const anchor = document.createElement('a');
      anchor.href = videoUrl;
      anchor.download = 'Birla-alokya.mp4';
      anchor.click();
    }

    const handlePriceDownloadButtonClick = () => {
      const imageUrl = '/utility/assets/birla-estates/birla-alokya/PriceList.jpeg';
      const anchor = document.createElement('a');
      anchor.href = imageUrl;
      anchor.download = 'Price-Image.jpeg';
      anchor.click();
    }

    const handleFloorDownloadButtonClick = () => {
      openDownloadModal()
      // const imageUrl = '/utility/assets/birla-estates/birla-alokya/floor-plan/FloorPlan-0.jpg';
      // const anchor = document.createElement('a');
      // anchor.href = imageUrl;
      // anchor.download = 'Floor-Image.jpg';
      // anchor.click();
    }
    
  

  const cardItems = [
    {
      id: 0,
      image: Brochureimage,
      title: "Brochure",
      button1: "Download",
      button2: "View",
      BirlaAlokya: "/utility/assets/birla-estates/birla-alokya/BirlaBrochure.pdf",
      viewButtonClick: handleBrochureViewButtonClick,
      downloadButtonClick:handleBrochureDownloadButtonClick
    },
    {
      id: 1,
      image: Projectvideoimage,
      title: "Project Video",
      button1: "Download",
      button2: "View",
      BirlaAlokya: "/utility/assets/birla-estates/birla-alokya/BirlaAlokya.mp4",
      viewButtonClick: handleVideoViewButtonClick,
      downloadButtonClick:handleVideoDownloadButtonClick
    },
    {
      id: 2,
      image: Projectpriceimage,
      title: "Project Price",
      button1: "Download",
      button2: "View",
      BirlaAlokya: "/utility/assets/birla-estates/birla-alokya/PriceList.jpg",
      viewButtonClick: handlePriceViewButtonClick,
      downloadButtonClick:handlePriceDownloadButtonClick
    },
    {
      id: 3,
      image: Floorplanimage,
      title: "Floor plan",
      button1: "Download",
      button2: "View",
      viewButtonClick: handleFloorViewButtonClick,
      downloadButtonClick: handleFloorDownloadButtonClick,
      BirlaAlokya:
        "/utility/assets/birla-estates/birla-alokya/floor-plan/FloorPlan-0.jpg",
    },
  ]
  
  const imageData = [
    {
      id: 1,
      name:"FloorPlan-0",
      type:"jpg",
      url:"/utility/assets/birla-estates/birla-alokya/floor-plan/FloorPlan-0.jpg",
    },
    {
      id:2,
      name:"FloorPlan-1",
      type:"png",
      url:"/utility/assets/birla-estates/birla-alokya/floor-plan/FloorPlan-1.png",
    },
    {
      id:3,
      name:"FloorPlan-2",
      type:"png",
      url:"/utility/assets/birla-estates/birla-alokya/floor-plan/FloorPlan-2.png",
    },
    {
      id:4,
      name:"FloorPlan-3",
      type:"jpg",
      url:"/utility/assets/birla-estates/birla-alokya/floor-plan/FloorPlan-3.jpg",
    },
    {
      id:5,
      name:"FloorPlan-4",
      type:"jpg",
      url:"/utility/assets/birla-estates/birla-alokya/floor-plan/FloorPlan-4.jpg",
    },
    ,
    {
      id:6,
      name:"FloorPlan-5",
      type:"jpg",
      url:"/utility/assets/birla-estates/birla-alokya/floor-plan/FloorPlan-5.jpg",
    },
    
    {
      id:7,
      name:"FloorPlan-6",
      type:"png",
      url:"/utility/assets/birla-estates/birla-alokya/floor-plan/FloorPlan-6.png",
    }

  ]

  const hideButton = id === "5"
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    centerPadding: "0px",
    centerMode: true,
    prevArrow: <CustomPrevArrow />,
    nextArrow: <CustomNextArrow />,

    responsive: [
      {
        breakpoint: 1024,
        settings: {
          dots: false,
          centerMode: true,
          centerPadding: "40px",
          slidesToShow: 3,
          prevArrow: <CustomPrevArrow />,
          nextArrow: <CustomNextArrow />,
        },
      },
    ],
  }

  return (
    <>
    <div className={`right-sidebar-details ${isOpen ? "open" : ""}`}>
      <div className="projectdetailsBackButton">
        <Container>
          <Row>
            <Col lg={3}>
              <div className="mt-2">
                <span onClick={toggleRightSidebar}>
                  <Link to="/developers/birla-estates-developer/">
                    <ArrowLeft /> Back
                  </Link>
                </span>
              </div>
            </Col>
            <Col lg={6}>
              <h5 className="m-0">Alokya</h5>
            </Col>
            <Col lg={3}>
              <Image src={BirlaAlokyaLogo} fluid className="headerImage m-0" />
            </Col>
          </Row>
        </Container>
      </div>

      <div className="projectdetailslist">
        {cardItems.map(card => (
          <div>
            <Container>
              <Col>
                <Row lg={2}>
                  <h6 className="projectdetailslist-card-title">
                    {card.title}
                  </h6>
                </Row>
                <Row lg={8}>
                  <Image src={card.image} alt="2" />
                </Row>
                {card.id !== 4 && (
                  <Row lg={2} className="projectdetailslist-card-buttons">
                    <Button className="card-buttons-download"  onClick={card.downloadButtonClick} >
                      {card.button1}
                    </Button>

                    {/* {imageData.map((image, index) => (
                      <div key={index}> */}
                        {/* <img src={image.url} alt={`Image ${index}`} /> */}
                        <Button
                          className="card-buttons-view"
                          onClick={card.viewButtonClick}
                        >
                          {card.button2}
                        </Button>
                      {/* </div>
                    ))} */}
                  </Row>
                )}
              </Col>
            </Container>
          </div>
        ))}
        <div className="projectdetailslist-tv">
          <h6 className="projectdetailslist-tv-title">Prop TV</h6>

          <VideoPlayerTv
            className="projectdetailslist-tv-video"
            videoUrl={videoUrl}
            thumbnailUrl={thumbnailUrl}
          />
        </div>

        <div className="footer-link">
          <Link
            target="_blank"
            to="https://www.propex.ai/projects/np-62436-birla-alokya/colive_ppm_52436"
          >
            <text>
              Click here for more details <ArrowRight />
            </text>
          </Link>
        </div>
      </div>
      {isDownlaodModalOpen && (
        <DownloadFileModal isOpen={isDownlaodModalOpen} onRequestClose={closedownloadModal} files={imageData} />
      )}
 {isModalOpen && (
        <FileModal isOpen={isModalOpen} onRequestClose={closeModal} files={imageData} />
      )}
      
    </div>
   
    </>
  )
}

export default BirlaAlokyaSidebar;
